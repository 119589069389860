import React, { useEffect, useRef } from 'react';
import p5 from 'p5';
import './PortfolioItem.css';

const Platool = () => {
  const canvasRef = useRef(null);

  useEffect(() => {
    const sketch = (p) => {
      let gridSize = 10;
      let dotSize = 2;
      let movementSpeed = 0.05;
      let maxMovement = 100;
      let baseWaveLength = 0.01;
      let waveLengthVariation = 0.005;
      let amplitudeSpeed = 0.02;
      
      const colors = {
        black: p.color(0),
        neonGreen: p.color(57, 255, 20)
      };

      p.setup = () => {
        p.createCanvas(300, 200);
      };

      p.draw = () => {
        p.background(colors.black);
        drawGrid(p);
      };

      function drawGrid(p) {
        let currentWaveLength = calculateWaveLength(p);
        for (let x = 0; x < p.width; x += gridSize) {
          let offsetY = calculateOffset(p, x, currentWaveLength);
          for (let y = -100; y < p.height * 2; y += gridSize) {
            p.fill(colors.neonGreen);
            p.noStroke();
            p.ellipse(x, y + offsetY, dotSize, dotSize);
          }
        }
      }

      function calculateWaveLength(p) {
        return baseWaveLength + p.sin(p.frameCount * amplitudeSpeed) * waveLengthVariation;
      }

      function calculateOffset(p, x, currentWaveLength) {
        return p.sin(p.frameCount * movementSpeed + x * currentWaveLength) * maxMovement;
      }
    };

    new p5(sketch, canvasRef.current);
  }, []);

  return (
    <div className="portfolio-item">
      <h2>BRB</h2>
      <div ref={canvasRef}></div>
      
    </div>
  );
};

export default Platool;