import React from 'react';
import './PortfolioItem.css';

// Import images
import walletAspire from './images/fib/_wallet_aspire.png';

// Import videos
import video731 from './images/fib/7-31.mp4';
import videoFibShow from './images/fib/fib_show.mp4';
import videoFibSynth1 from './images/fib/fibsynth1.mp4';
import videoMusicalFib from './images/fib/MusicalFib.mp4';
import videoMusicalFib2 from './images/fib/MusicalFib2.mp4';

const Fib = () => {
  return (
    <div className="portfolio-item">
      <h2>Fib</h2>
      <img src={process.env.PUBLIC_URL + '/images/fib/33.svg'} alt="33" />

      <p>
        Behold the mesmerizing series of Fibonacci rectangles, a visual symphony where mathematics meets art. Each rectangle, meticulously crafted, unfolds the timeless beauty of the Fibonacci sequence. The harmonious proportions and intricate patterns invite the viewer into a realm where logic and aesthetics converge, creating a captivating dance of form and color. This series is a testament to the elegance of mathematical principles, rendered in a way that is both intellectually stimulating and visually enchanting.
      </p>
      <div className="fib-images">
      <img src={process.env.PUBLIC_URL + '/images/fib/55.svg'} alt="55" />
        
        <div className="grid">
        <img src={process.env.PUBLIC_URL + '/images/fib/33.svg'} alt="33" />
          <img src={process.env.PUBLIC_URL + '/images/fib/4.svg'} alt="4" />
          <img src={process.env.PUBLIC_URL + '/images/fib/7.svg'} alt="7" />
        </div>
        <div className="grid">
          <img src={process.env.PUBLIC_URL + '/images/fib/13.svg'} alt="13" />
          <img src={process.env.PUBLIC_URL + '/images/fib/16.svg'} alt="16" />
          <img src={process.env.PUBLIC_URL + '/images/fib/25.svg'} alt="25" />
        </div>
        <img src={process.env.PUBLIC_URL + '/images/fib/16.svg'} alt="16" />

      </div>
          <img src={walletAspire} alt="Wallet Aspire" />
      <p>
        These Fibonacci rectangles are not only visually captivating but also hold the potential for recursive applications in the realm of Bitcoin. By leveraging the recursive nature of the Fibonacci sequence, these rectangles can be loaded and their information utilized in various innovative ways, such as generating music. The mathematical properties inherent in the Fibonacci sequence can be translated into musical compositions, creating harmonious and aesthetically pleasing auditory experiences. This intersection of mathematics, technology, and art showcases the versatility and depth of the Fibonacci sequence, extending its influence beyond visual art into the digital and auditory domains.
      </p>
      <div className="videos-grid">
        <video controls>
          <source src={video731} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      
  
        <video controls>
          <source src={videoMusicalFib2} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
        <video controls>
          <source src={videoFibSynth1} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
    </div>
  );
};

export default Fib;