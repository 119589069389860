import React, { useEffect, useRef } from 'react';
import './PortfolioItem.css';

const Spirals = () => {

  return (
    <div className="portfolio-item">
      <h1>Spirals</h1>
      <img src={process.env.PUBLIC_URL + '/images/spirals/26.svg'} alt="Hedera Spiral 1" />
      <p>
        SVG Spirals generated with Python.  Gen 3 are the first generation of spirals inscribed as Ordinals on Bitcoin.
      </p>
      <button onClick={() => window.location.href = 'https://gamma.io/ordinals/collections/spirals_'}>View BTC Collection</button>
      <button onClick={() => window.location.href = 'https://sentx.io/nft-marketplace/hspirals'}>View HBAR Collection</button>

      <h2>BTC Ordinals</h2>
      <div className="grid">
      <img src={process.env.PUBLIC_URL + '/images/spirals/gen3/1.svg'} alt="Spiral 1" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen3/3.svg'} alt="Spiral 3" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen3/4.svg'} alt="Spiral 4" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen3/7.svg'} alt="Spiral 7" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen3/40.svg'} alt="Spiral 40" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen3/48.svg'} alt="Spiral 48" />
      </div>
      <video autoPlay loop muted src={process.env.PUBLIC_URL + '/images/spirals/livingSpiral.mp4'} alt="Spiral 1" />
      <p>
        BTC Spirals inscribed as Ordinals on Bitcoin can be recursively brought to life by code.
      </p>
      <h2>HBAR Gen 1</h2>
      <div className="grid">
        <img src={process.env.PUBLIC_URL + '/images/spirals/68.svg'} alt="Spiral 68" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/78.png'} alt="Spiral 78" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/82.svg'} alt="Spiral 82" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/89.svg'} alt="Spiral 89" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/94.svg'} alt="Spiral 94" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/43.svg'} alt="Spiral 43" />
      </div>
      <h2>HBAR Gen 2</h2>
      <p>
         Gen 2 is based on the trading data of Hedera on June 7: <br/>
         Buy = Green Palette <br/>
         Sell = Red Palette <br/>
         Amount = Size of the spiral <br/>
         Digits of price = Unknown various effects on appearance.
      </p>
      <div className="grid">
      <img src={process.env.PUBLIC_URL + '/images/spirals/gen2/21.svg'} alt="Spiral 21" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen2/8.svg'} alt="Spiral 8" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen2/33.svg'} alt="Spiral 82" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen2/39.svg'} alt="Spiral 39" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen2/14.svg'} alt="Spiral 14" />
        <img src={process.env.PUBLIC_URL + '/images/spirals/gen2/53.svg'} alt="Spiral 53" />
      </div>
     
    </div>
  );
}

export default Spirals;