import React from 'react';
import './Overlay.css';

const Overlay = ({ isOpen, onClose, content }) => {
  if (!isOpen) return null;

  return (
    <div className="overlay">
      <div className="overlay-content">
        <button className="close-button" onClick={onClose}>×</button>
        {content}
      </div>
    </div>
  );
};

export default Overlay;