import React from 'react';
import Portfolio from './components/Portfolio';
import './App.css'; // Ensure this is imported if you have global styles

function App() {
  return (
    <div className="App">
      <Portfolio />
    </div>
  );
}

export default App;
