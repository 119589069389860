import React, { useEffect, useState } from 'react';
import './FooterOverlay.css';

const FooterOverlay = () => {
  const [titlesWithLinks, setTitlesWithLinks] = useState([]);

  useEffect(() => {
    // Manually list the titles of the components
    const pageTitles = [
      'Fib',
      'Spirals',
      '3DP2A Customizer',
      'Blank',
      'No!',
      'Sketchverse',
      'Platool',
      'Digital Art',
      'Conv3rgence',
      'P5 / Creative Code',
      'Cube Story',
      'Hashbits',
      'WXRSHIP'
    ];
    const pageTitlesWithLinks = pageTitles.map(title => {
      const anchorName = title.toLowerCase().replace(/[^a-z0-9]/g, '');
      return <a key={anchorName} href={`#${anchorName}`}>{title}</a>;
    });

    setTitlesWithLinks(pageTitlesWithLinks);
  }, []);

  return (
    <div className="footer-overlay">
      {titlesWithLinks.length > 0 ? titlesWithLinks.reduce((prev, curr) => [prev, ' | ', curr]) : null}
    </div>
  );
};

export default FooterOverlay;