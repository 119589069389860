import React from 'react';
import './PortfolioItem.css';

const Customizer = () => {
  return (
    <div className="portfolio-item">
      <h2>3DP2A Customizer</h2>
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/3dp.png'} alt="3DP2A Customizer Interface" />
        <video controls>
          <source src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/statustreg.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      <p>
        The 3DP2A Customizer is an innovative tool designed for enthusiasts of 3D printed firearms. This web-based application allows users to visualize and plan the aesthetic appearance of their 3D printed firearm projects before committing to the actual printing process. By offering a user-friendly interface, the customizer enables individuals to experiment with various color combinations and patterns, ensuring that the final product meets their visual preferences.
      </p>
      
        <video controls>
          <source src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/v0release.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <video controls>
          <source src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/v1.mp4'} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      
      <p>
        One of the key features of the 3DP2A Customizer is its integration with e-commerce platforms. As users design their ideal color scheme, the application generates dynamic links to purchase the exact filament colors chosen for the project. This seamless connection between design and procurement streamlines the process for users, allowing them to easily source the materials needed to bring their customized designs to life. Whether you're looking to create a subtle, monochromatic piece or a bold, multi-colored statement, the 3DP2A Customizer provides the tools and resources to plan and execute your vision with precision.
      </p>
      <div className="grid" style={{ backgroundColor: 'green' }}>
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/signal16.gif'} alt="3DP2A Customizer Animation" style={{ width: '32px' }} />
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/signal16.gif'} alt="3DP2A Customizer Animation" style={{ width: '32px' }} />
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/signal16.gif'} alt="3DP2A Customizer Animation" style={{ width: '32px' }} />
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/signal16.gif'} alt="3DP2A Customizer Animation" style={{ width: '32px' }} />
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/signal16.gif'} alt="3DP2A Customizer Animation" style={{ width: '32px' }} />
        <img src={process.env.PUBLIC_URL + '/images/3dp2acustomizer/signal16.gif'} alt="3DP2A Customizer Animation" style={{ width: '32px' }} />
      </div>
    </div>
  );
};

export default Customizer;